import React from 'react';
import './resume.css';
import ProjectData from '../Projectdata.json';

const Resume = () => {
    return (
        <div className="container fullResumeWrap">
            <div className="wrap">
                <div className="row col-sm-10 col-sm-offset-1">
                    <h2 className="title">Ravi Kumar <span>Learning • Developing • Deploying</span></h2>
                    <p className='descp'>Software Engineer, Noida (India)</p>
                    <ul className='contactInfo'>
                        <li><i className="fa fa-envelope mr-2" aria-hidden="true"></i>Email - <a href="mailto:connect@ravee.in">connect [at] ravee [dot] in</a></li>
                        <li><i className="fa fa-github-square mr-2" aria-hidden="true"></i>GitHub - <a href="https://github.com/ravee-in/">ravee-in</a></li>
                        <li><i className="fa fa-linkedin mr-2"></i>LinkedIn - <a href="https://www.linkedin.com/in/ravee7284" target="_blank" rel="noreferrer"> @ravee7284 </a></li>
                    </ul>
                </div>

                <div className="row col-sm-10 col-sm-offset-1 experience">
                    <h2 className="EXPtitle">Experience</h2>

                    <hr className='separator' />

                    <div className="jobWrap mb-5 px-2">
                        <h2 className="jobTitle">Software Engineer <span>Nov 2019 - Present</span></h2>
                        <p className="jobSubTitle">TalentMovers Consulting (P) Ltd - Noida, India</p>
                        <ul className='jobDescription'>
                            <li>Responsible for designing Frontend of the websites from scratch using HTML/CSS/JS and their frameworks. </li>
                            <li>Worked as Frontend lead to revamp reporting dashboards for user management and data analysis, resulting in faster queries, and more useful, efficient and aesthetically pleasing graphs, charts, and tables.</li>
                            <li>Experienced in CMS (WordPress) based designing of Multilingual Websites (both LTR & RTL).</li>
                            <li>Creating APP UI elements using Photoshop. And, converting visuals (PSD mockups) into working prototypes and webpages.</li>
                            <li>Design and develop the FrontEnd components and works with the Backend team to integrate Client-side and serve-side of websites.</li>
                            <li>Interacts with clients to understand the business model and web app requirements.</li>
                            <li>Proficiently design & develop cross-browser compatible production ready solutions.</li>
                            <li>Worked on eCommerce and membership-based projects related to Health, Education, Business domains.</li>
                            <li>Analyze data, processes, codes and websites to troubleshoot problems and identify areas for improvement.</li>
                        </ul>
                    </div>

                    <div className="jobWrap mb-5 px-2 px-2">
                        <h2 className="jobTitle">Web Developer <span>Aug 2018 - Nov 2019</span></h2>
                        <p className="jobSubTitle">Smartech Education (P) Ltd - Delhi, India</p>
                        <ul className='jobDescription'>
                            <li>Involved in HTML coding and raw structure of website.</li>
                            <li>Design WordPress based websites and templates.</li>
                            <li>SEO and Speed Optimized landing page designs for various businesses</li>

                        </ul>
                    </div>

                </div>

                <div className="row col-sm-10 col-sm-offset-1 experience">
                    <h2 className="EXPtitle">Skills</h2>
                    <hr className='separator' />

                    <ul className='skills'>
                        <li><strong>Languages - </strong> JavaScript( ReactJS ), HTML5, CSS3</li>
                        <li><strong>Frameworks - </strong> Bootstrap 3/4/5 | Material UI Library</li>
                        <li><strong>Softwares - </strong> VS Code, Sublime, Adobe Photoshop & Illustrator, MS Word, MS Excel</li>
                        <li><strong>Concepts - </strong>Web Application Design and Development, Databases (MySQL, MongoDB), Version Control (Git and Github), Hosting & Servers (Shared and VPS Hosting), Testing.</li>
                    </ul>
                </div>

                <div className="row col-sm-10 col-sm-offset-1 experience">
                    <h2 className="EXPtitle">PROJECTS</h2>
                    <hr className='separator' />

                    <ul className='projectList'>
                        {ProjectData.map((element) => {
                            return <li key={element.key}><strong>{element.projectTitle}</strong> - {element.TechUsed} -  <a href={element.link}>View</a> </li>
                        }).reverse()}
                    </ul>
                </div>


                <div className="row col-sm-10 col-sm-offset-1 experience">
                    <h2 className="EXPtitle">EDUCATION</h2>
                    <hr className='separator' />


                    <h2 className="jobTitle">Bachelor of Computer Science (H) <span>2015 - 2018</span></h2>
                    <p className="jobSubTitle">CVS, University of Delhi - Delhi, India</p>

                </div>

            </div>
        </div>
    )
}

export default Resume