import React from 'react';
import TechData from './Tech.json';

function Technologies() {

    return (
        <section id="Technologies" className="pt50 pb100 section-light">
            <div className="container">
                <div className="row">
                <h4 className='secTitle'>Technologies I Use</h4>
                    <div className="Techgallery">
                        {TechData.map((element) => {
                            return <div className="TechImgWrap" key={element.key}>
                                <img className="techImg" src={element.techLogo} alt={element.techTitle} title={element.techTitle}/>
                                <div className="tectTitle">
                                    <h5 style={{display: "none"}}>{element.techTitle}</h5>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Technologies;
