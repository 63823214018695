import './App.css';
import React from 'react';
import About from './components/About';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Technologies from './components/Technologies';
import Resume from './components/resume/Resume';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    
      <>
      <Router>
        <Navbar title="Ravee.in" hireMe="https://docs.google.com/forms/d/e/1FAIpQLSct9CJa3Iv0TXzmTrJ4I6bCdeFFXduOmzG3XxC0mUPJ9J9YYQ/viewform" />

        <Switch>
          <Route exact path="/">
            <Hero hContent="Frontend Developer with over 3 Years of professional experience in web designing and UI development using latest web technologies. Highly skilled in wire-framing, designing, and developing creative digital experiences using standard HTML | CSS | JavaScript | jQuery | Bootstrap | ReactJs practices and WordPress." />
            <Projects />

            <Technologies />
            <About hContent="Frontend Developer with over 3 Years of professional experience in web designing and UI development using latest web technologies. Highly skilled in wire-framing, designing, and developing creative digital experiences using standard HTML | CSS | JavaScript | jQuery | Bootstrap | ReactJs practices and WordPress." />
          </Route>

          <Route exact path="/resume">
            <Resume />
          </Route>
        </Switch>

        
        <Footer />
        </Router>

      </>
  );
}

export default App;
