import React from 'react';

function Hero(props) {
    
    return (
        <section id="hero-section" className="pt100">
            <div className="cloud x1"></div>
            <div className="cloud x2"></div>
            <div className="cloud x3"></div>
            <div className="cloud x4"></div>
            <div className="cloud x5"></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="intro-text text-center">
                            <h4>{props.hContent}</h4>
                            <a href="#projects">{props.linkText}</a>
                            <div className="scroll-arrow">
                                {/* <svg id="scroll-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 78.4 77.9">
                                    <circle cx="39.4" cy="38.9" r="37.2" />
                                    <circle className="hover" cx="39.4" cy="38.9" r="37.2" />
                                    <path d="M26.2 35.9L39.4 49l13.2-13.1" />
                                </svg> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
