import React from 'react';

function About(props) {
    return (
        <section id="about-area" className="pt100 pb100 section-light">
            <div className="cloud x1"></div>
            <div className="cloud x2"></div>
            <div className="cloud x3"></div>
            <div className="cloud x4"></div>
            <div className="cloud x5"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-6">
                        <h3>About</h3>
                        <p>{props.hContent}</p>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="pl30">
                            <h3>Contact</h3>
                            <p><i className="fa fa-envelope" aria-hidden="true"></i><a href="mailto:connect@ravee.in">connect(at)ravee(dot)in</a></p>
                            <p><i className="fa fa-globe" aria-hidden="true"></i><a href="https://ravee.in">ravee(dot)in</a></p>
                            <p><i className="fa fa-whatsapp"></i><a href="https://wa.me/917011397546">+91-7011397546</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
