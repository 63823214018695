import React from 'react';

function Projectitem(props) {

    let { projectTitle, subTitle, thumbImg, zoomImg, tech, linkToProject } = props;

    return (
        <div className="col-md-4 col-sm-6 padding-none mobileCol">
            <div className="projectWrap">
                <p className='ActionsT'>
                    <span className='techBadge'>{tech}</span><a href={linkToProject} target="_blank" rel="noreferrer" className='actionCTA'>View Project</a>
                </p>
                <a className="single-project-item" title={projectTitle} href={zoomImg}>
                    <img className="full-width" src={thumbImg} alt="" />
                    <div className="grid-caption">
                        <h2>{projectTitle}</h2>
                        <p>{subTitle}</p>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Projectitem;
