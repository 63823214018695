import React from 'react';
import { Link } from 'react-router-dom';

function Navbar(props) {
    return (
        <header id="header" className="header">
            <div className="container">
                <div className="row col-sm-10 col-sm-offset-1">
                    <nav className="navbar navbar-default">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a className="navbar-brand" href="/"><i className="pe-7s-user"></i> <span>{props.title}</span></a></div>
                        <div id="navbar" className="navbar-collapse collapse">
                            <ul className="nav navbar-nav navbar-nav-top navbar-right">
                                <li><a href="#projects">Projects</a></li>
                                <li><a href="#about-area">About</a></li>
                                <li><a href={props.hireMe} target="_blank" rel='noreferrer'>Hire Me </a></li>
                                <li><Link to='/resume'>Resume</Link></li>
                                {/* <li><a href="#" data-toggle="modal" data-target="#contact-modal">Hire Me </a></li>  */}
                                <li className="hlighted"><a href="https://www.linkedin.com/in/ravee7284" target="_blank" rel='noreferrer'><i className="fa fa-linkedin"></i> </a></li>
                                <li className="hlighted"><a href="https://github.com/ravee-in/" target="_blank" rel='noreferrer'><i className="fa fa-github" aria-hidden="true"></i> </a></li>
                                <li className="hlighted"><a href="https://www.buymeacoffee.com/ravee7284" target="_blank" rel='noreferrer'><i className="fa fa-coffee" aria-hidden="true"></i> </a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
