import React from 'react';

function Footer() {
    return (
        <footer id="footer" className="pt100 pb100">
            <div className="container">
                <div className="row">
                    <div className="social-icons text-center">
                        <a href="https://www.linkedin.com/in/ravee7284"><i className="fa fa-linkedin"></i></a>
                        <a href="https://www.behance.net/ravee"><i className="fa fa-behance"></i></a>
                        <a href="https://wa.me/917011397546"><i className="fa fa-whatsapp"></i></a>
                    </div>
                    <div className="copyright text-center">
                        <p>© Copyright 2019-2022 All Right Reserved. Made with <a href="/"><i className="fa fa-heart "></i></a> By <a href="https://ravee.in">Ravee.in</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
