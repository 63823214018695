import React from 'react';
import ProjectData from './Projectdata.json';
import Projectitem from './Projectitem';

function Projects() {

    return (
        <section id="projects" className="pt100 pb100">
            <div className="container">
                <div className="row">
                    <h4 className='secTitle'>Recent Work</h4>
                    <div className="zoom-gallery">
                        {ProjectData.map((element) => {
                            return <Projectitem key={element.key} projectTitle={element.projectTitle} subTitle={element.subTitle} thumbImg={element.thumbImg} zoomImg={element.zoomImg} tech={element.TechUsed} linkToProject={element.link} />
                        }).reverse()}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;
